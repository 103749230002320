import { authStateCommon } from '@pig-common/recoils'
import { useQuery } from '@tanstack/react-query'
import { gamesApi } from '@pig-casino/api'
import { useRecoilValue } from 'recoil'
import { CommonResponse } from '@pig-common/types/Api.type'
import { IGameItem } from '@pig-casino/types/Game.type'
import { SearchParamType } from '@pig-casino/types/Search.type'

interface ListGameHookType<SelectReturnType> {
  params?: SearchParamType
  select?: (data: CommonResponse<IGameItem[]>) => SelectReturnType
  key?: string[]
  enabled?: boolean
}

export default function useListGame<SelectReturnType = IGameItem[]>({
  params = {},
  key = [],
  select,
  enabled,
}: ListGameHookType<SelectReturnType>) {
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)
  const { customerCode } = useRecoilValue(authStateCommon.authState)

  return useQuery({
    queryKey: [gamesApi.key.list, ...key],
    queryFn: () =>
      gamesApi.list(
        {
          customerCode: isAuthenticated && customerCode,
          ...params,
        } || {},
      ),
    enabled,
    select: (data) => (select ? select(data) : data?.data || []),
    refetchOnWindowFocus: true,
  })
}
