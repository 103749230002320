import { useEffect, useState } from 'react'
import cx from 'classnames'
import { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import useListGame from '@pig-casino/hooks/useListGame'
import { useMediaQuery } from '@pig-frontend/uikit'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { gamesApi } from '@pig-casino/api'
import { bannerButtonConfig, BannerButtonConfigType } from './config'
import { pageLink } from '@pig-casino/constants/page-name'
import { useRecoilValue } from 'recoil'
import { authStateCommon } from '@pig-common/recoils'
import { userAgentType } from '@pig-common/types/Device.type'
import { Badge } from 'antd'
import styles from './index.module.scss'
import useGetReadFlag from '@pig-common/hooks/useGetReadFlag'

interface IGameBannerProps {
  userAgent?: userAgentType
}

export default function GameBanner({ userAgent }: IGameBannerProps) {
  const router = useRouter()
  const isMD = useMediaQuery('md', userAgent)
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)
  const [isDomMounted, setDomMounted] = useState<boolean>(false)
  const { data: games = [] } = useListGame({
    key: [gamesApi.key.highlight],
    params: { filterType: 'highlight' },
  })
  const { data: dailyQuestReadFlag } = useGetReadFlag({
    params: { fieldName: 'DAILY_QUESTS' },
    enabled: false, // fetched in layout
  })

  useEffect(() => {
    setDomMounted(true)
  }, [])

  const shouldDisabled = (buttonItem: BannerButtonConfigType): boolean => {
    if (buttonItem?.disabled) {
      return true
    }
    if (buttonItem.authProtected) {
      if (isAuthenticated) {
        return false
      }
      return true
    }
    return false
  }

  return (
    <div className={styles.container}>
      <Swiper
        slidesPerView={1}
        modules={[Pagination, Autoplay]}
        loop
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        className={styles.swiper}
      >
        {games.map((game) => (
          <SwiperSlide key={`game-banner-${game.gameId}`}>
            <div
              onClick={() =>
                router.push(
                  `${pageLink.game}/${game.gameName.replace(/ /g, '-')}`,
                )
              }
              onKeyDown={() =>
                router.push(
                  `${pageLink.game}/${game.gameName.replace(/ /g, '-')}`,
                )
              }
              className={styles['game-banner']}
            >
              <Image
                src={game?.lobbyBannerUrl}
                alt={game?.gameName}
                fill
                style={{ objectFit: 'cover' }}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={styles['button-section']}>
        {bannerButtonConfig.map((buttonItem) => (
          <div
            key={buttonItem.key}
            className={cx(styles['button-box'], {
              [styles['button-disabled']]:
                isDomMounted && shouldDisabled(buttonItem),
            })}
            onKeyDown={() =>
              buttonItem?.link ? router.push(buttonItem.link) : null
            }
            onClick={() =>
              buttonItem?.link ? router.push(buttonItem.link) : null
            }
          >
            <Badge
              dot={
                buttonItem?.hasBadge &&
                buttonItem.hasBadge(!dailyQuestReadFlag?.flagStatus)
              }
            >
              <Image
                src={buttonItem.image}
                width={isMD ? 60 : 42}
                height={isMD ? 60 : 42}
                alt={buttonItem.alt}
              />
            </Badge>
            <span className={styles.label}>{buttonItem.title}</span>
          </div>
        ))}
      </div>
    </div>
  )
}
