import { Swiper, SwiperSlide } from 'swiper/react'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useRouter } from 'next/router'
import { Section } from '@pig-frontend/uikit'
import SliderCard from './card'
import { pageLinkCommon } from '@pig-common/constants/page-name'
import { IChampionItem } from '@pig-casino/types/Champion.type'
import styles from './index.module.scss'

export interface IChampionSliderProps {
  champions?: IChampionItem[]
}

export default function ChampionSlider({
  champions = [],
}: IChampionSliderProps) {
  const router = useRouter()

  const clickLink = () => {
    router.push(pageLinkCommon.champion)
  }

  return (
    <Section
      title="แชมป์เปี้ยน"
      className={styles.container}
      padding={false}
      extra={
        <div className={styles['section-extra']}>
          <ArrowRightOutlined style={{ fontSize: 16 }} onClick={clickLink} />
        </div>
      }
    >
      <Swiper spaceBetween={8} slidesPerView={1} width={330}>
        {champions.map((champion) => (
          <SwiperSlide key={champion?.uid}>
            <SliderCard champion={champion} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Section>
  )
}
