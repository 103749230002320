import { Swiper, SwiperSlide } from 'swiper/react'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useRouter } from 'next/router'
import { Section } from '@pig-frontend/uikit'
import SliderCard from './card'
import { IGameItem } from '@pig-casino/types/Game.type'
import styles from './index.module.scss'

export interface IGameSliderProps {
  games?: IGameItem[]
  title: string
  favIds: string[]
  link?: string
  onClickPlay: (game: IGameItem) => void
  type?: string
}

export default function GameSlider({
  games = [],
  title,
  favIds,
  onClickPlay,
  link,
  type,
}: IGameSliderProps) {
  const router = useRouter()

  const clickLink = () => {
    if (link) {
      router.push(link)
    }
  }

  return (
    <Section
      title={title}
      className={styles.container}
      padding={false}
      extra={
        <div className={styles['section-extra']}>
          <ArrowRightOutlined style={{ fontSize: 16 }} onClick={clickLink} />
        </div>
      }
    >
      <Swiper
        spaceBetween={8}
        slidesPerView={1}
        width={272}
        breakpoints={{
          768: {
            slidesPerView: 1,
            width: 340,
          },
        }}
      >
        {games.map((game) => (
          <SwiperSlide key={game?.gameId}>
            <SliderCard
              game={game}
              favIds={favIds}
              onClickPlay={onClickPlay}
              type={type}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Section>
  )
}
