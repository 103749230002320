import { useEffect, useState } from 'react'
import cx from 'classnames'
import { Section } from '@pig-frontend/uikit'
import { Image } from '@pig-frontend/uikit'
import { partnersConfig, PartnerKeysType } from './config'
import { useRouter } from 'next/router'
import isEmpty from 'lodash/isEmpty'
import styles from './index.module.scss'

interface IPartnerSectionProps {
  title?: string | object
  className?: string
  imageWrapperClassName?: string
  sectionClassName?: string
  /** Partner image will be white and good for colored BG (not white BG) */
  isWhiteImage?: boolean
  blackListKey?: PartnerKeysType[]
}

export default function PartnerSection({
  title,
  className,
  imageWrapperClassName,
  sectionClassName,
  isWhiteImage,
  blackListKey = [],
}: IPartnerSectionProps) {
  const router = useRouter()
  const [partners, setPartners] =
    useState<typeof partnersConfig>(partnersConfig)

  useEffect(() => {
    if (!isEmpty(blackListKey)) {
      const whiteListPartners = partnersConfig.filter(({ key }) => {
        return !blackListKey.includes(key)
      })
      setPartners(whiteListPartners)
    }
  }, [partnersConfig, blackListKey])

  return (
    <Section
      title={title || 'พาร์ทเนอร์'}
      sectionClassName={sectionClassName}
      className={cx(styles.container, className)}
    >
      {partners.map(({ key, image, whiteImage, link }) => (
        <div
          key={key}
          className={cx(styles.item, imageWrapperClassName)}
          {...(link
            ? { role: 'button', onClick: () => router.push(link) }
            : {})}
        >
          <Image
            src={isWhiteImage ? whiteImage : image}
            alt={key}
            className={cx(styles.img, 'partner-img')}
          />
        </div>
      ))}
    </Section>
  )
}
