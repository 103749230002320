import { useQuery } from '@tanstack/react-query'
import { promotionsApi } from '@pig-casino/api'
import { CommonResponse } from '@pig-common/types/Api.type'
import {
  IPromotionsData,
  IPromotionsResultData,
} from '@pig-casino/types/Promotions.type'

interface ListPromotionsType {
  params?: promotionsApi.IListParamsProps
  select?: (data: CommonResponse<IPromotionsData>) => IPromotionsResultData[]
  enabled?: boolean
}

export default function useListPromotion({
  params = {},
  select,
  enabled,
}: ListPromotionsType) {
  return useQuery({
    queryKey: [promotionsApi.key.list],
    queryFn: () => promotionsApi.list(params),
    enabled: enabled,
    select: (data) => (select ? select(data) : data?.data?.results || []),
  })
}
