import Link from 'next/link'
import { Image } from '@pig-frontend/uikit'
import { IChampionItem } from '@pig-casino/types/Champion.type'
import { pageLink } from '@pig-casino/constants/page-name'
import Confetti from 'react-confetti'
import styles from './index.module.scss'
import {
  ChampionSAIcon,
  ChampionCQ9Icon,
  ChampionSexyIcon,
  ChampionBGIcon,
  ChampionEVOIcon,
  ChampionDreamGamingIcon,
} from '@pig-casino/constants/images'

interface SliderCardProps {
  champion: IChampionItem
}

export default function SliderCard({ champion }: SliderCardProps) {
  const getChampionIcon = (gameRefType: string) => {
    let imageSrc = ChampionEVOIcon
    switch (gameRefType) {
      case 'SA':
        imageSrc = ChampionSAIcon
        break
      case 'CQ9':
        imageSrc = ChampionCQ9Icon
        break
      case 'SEXY':
        imageSrc = ChampionSexyIcon
        break
      case 'BG':
        imageSrc = ChampionBGIcon
        break
      case 'EVOLUTION':
        imageSrc = ChampionEVOIcon
        break
      case 'DREAM_GAMING':
        imageSrc = ChampionDreamGamingIcon
        break
      default:
        imageSrc = ChampionEVOIcon
        break
    }
    return imageSrc
  }

  return (
    <div className={styles['champion-card']}>
      <Confetti
        colors={['#7C588F', '#9A2ED1', '#CD94EB']}
        initialVelocityY={{ min: 0, max: 4 }}
        initialVelocityX={{ min: -2, max: 2 }}
        gravity={0.02}
        friction={0.98}
        height={140}
        width={300}
        numberOfPieces={12}
        drawShape={(ctx) => {
          const w = 6
          const h = 2
          ctx.beginPath()
          ctx.rect(-w / 2, -w / h, w, h)
          ctx.stroke()
          ctx.fill()
          ctx.closePath()
        }}
      />
      <div className={styles['champion-body']}>
        <div>
          <Image
            width={90}
            height={90}
            src={getChampionIcon(champion?.gameRefType)}
            alt={champion?.imageUrl}
          />
        </div>
        <div className={styles.body}>
          <p className={styles.username}>{champion?.userDisplayName}</p>
          <p
            className={styles.title}
          >{`ได้รับเงินรางวัล ${champion?.totalPayout} บาท`}</p>
          <p className={styles.subtitle}>
            {'จากค่ายเกม '}
            <Link className={styles.link} href={pageLink.games.providers}>
              {champion?.gameRefType}
            </Link>
          </p>
          <span
            className={styles.description}
          >{`ชนะ ${champion?.roundStack} ครั้ง`}</span>
          <span>{` (เดินพัน ${champion?.totalBet} บาท)`}</span>
        </div>
      </div>
    </div>
  )
}
