import {
  lobbyMenuGame,
  lobbyMenuQuest,
  lobbyMenuChampion,
  lobbyMenuReward,
  lobbyMenuGuild,
} from '@pig-casino/constants/images'
import { pageLinkCommon } from '@pig-common/constants/page-name'
import { pageLink } from '@pig-casino/constants/page-name'
import { StaticImageData } from 'next/image'

export type BannerButtonConfigType = {
  image: StaticImageData | string
  key: string
  alt: string
  title: string
  link?: string
  authProtected?: boolean
  disabled?: boolean
  hasBadge?: (props?: boolean) => boolean
}

export const bannerButtonConfig: BannerButtonConfigType[] = [
  {
    key: 'lobby-menu-game',
    alt: 'Lobby menu game',
    image: lobbyMenuGame,
    title: 'เกมทั้งหมด',
    link: pageLink.games.index,
  },
  {
    key: 'lobby-menu-quest',
    alt: 'Lobby menu quest',
    image: lobbyMenuQuest,
    title: 'ภารกิจ',
    authProtected: true,
    link: pageLinkCommon.dailyQuests,
    hasBadge: (isShowBadge) => isShowBadge,
  },
  {
    key: 'lobby-menu-champion',
    alt: 'Lobby menu champion',
    image: lobbyMenuChampion,
    title: 'แชมป์เปี้ยน',
    link: pageLinkCommon.champion,
  },
  {
    key: 'lobby-menu-reward',
    alt: 'Lobby menu reward',
    image: lobbyMenuReward,
    title: 'แลกรางวัล',
    authProtected: true,
    link: pageLinkCommon.pigShop,
  },
  {
    key: 'lobby-menu-guild',
    alt: 'Lobby menu guild',
    image: lobbyMenuGuild,
    title: 'กิลด์',
    authProtected: true,
    link: pageLinkCommon.guild.index,
  },
]
