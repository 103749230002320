import { Section } from '@pig-frontend/uikit'
import { Image } from '@pig-frontend/uikit'
import { paymentsConfig } from './config'
import styles from './index.module.scss'
import Link from 'next/link'
import { pageLinkCommon } from '@pig-common/constants/page-name'

export default function PaymentSection() {
  return (
    <Section title="ช่องทางการเติมเงิน" className={styles.container}>
      <div className={styles['images-container']}>
        {paymentsConfig.map(({ alt, image, key, title, subTitle }) => (
          <div key={key} className={styles.items}>
            <Image src={image} alt={alt} className={styles.img} />
            <span className={styles.title}>{title}</span>
            <span className={styles['sub-title']}>{subTitle}</span>
          </div>
        ))}
      </div>

      <h1>ทำไมต้อง PIGBET?</h1>
      <ul className={styles['content-container']}>
        <li>
          เว็บคาสิโนออนไลน์รูปแบบใหม่ บาคาร่าระบบตรง ไม่ต้องโยกเว็บ โยกกระเป๋า
        </li>
        <li>
          โปรโมชั่นมากมายไม่ว่าจะเป็น{' '}
          <Link href={pageLinkCommon.promotionsSlug.freeCredit}>เครดิตฟรี</Link>{' '}
          ,{' '}
          <Link href={pageLinkCommon.promotionsSlug.referral}>แนะนำเพื่อน</Link>
          ,{' '}
          <Link href={pageLinkCommon.promotionsSlug.returnBonus}>
            คืนยอดเสีย
          </Link>
        </li>
        <li>
          ฝาก-ถอน อัตโนมัติหลากหลายช่องทาง ไม่ว่าจะเป็นการสแกน QR, โอนเงิน และ{' '}
          <a href="https://www.truemoney.com/" rel="nofollow">
            {' '}
            True wallet{' '}
          </a>
        </li>
        <li>
          คาสิโนออนไลน์ เกมอื่นๆ ครบคัน ไม่ว่าจะเป็น บาคาร่า ไฮโล รูเล็ต
          เสือมังกร กงล้อและเกมโชว์ต่างๆคาสิโนออนไลน์ เกมอื่นๆ ครบคัน
          ไม่ว่าจะเป็น บาคาร่า ไฮโล รูเล็ต เสือมังกร กงล้อและเกมโชว์ต่างๆ
        </li>
      </ul>
    </Section>
  )
}
