import { useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import { Button } from '@pig-frontend/uikit'
import {
  SoundFilled,
  GlobalOutlined,
  UserOutlined,
  StarOutlined,
  StarFilled,
} from '@ant-design/icons'
import { Image } from '@pig-frontend/uikit'
import { LobbyGameLiveIcon } from '@pig-casino/constants/images'
import { useRecoilValue } from 'recoil'
import { authStateCommon } from '@pig-common/recoils'
import useFavoriteGame from '@pig-casino/hooks/useFavoriteGame'
import { IGameItem } from '@pig-casino/types/Game.type'
import { gameType } from '@pig-casino/constants/game'
import usePlayGame from '@pig-casino/hooks/usePlayGame'
import useListGame from '@pig-casino/hooks/useListGame'
import styles from './index.module.scss'

interface SliderCardProps {
  game: IGameItem
  onClickPlay: (game: IGameItem) => void
  favIds: string[]
  type: string
}

export default function SliderCard({
  game,
  onClickPlay,
  favIds,
  type,
}: SliderCardProps) {
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)
  const [isDomMounted, setDomMounted] = useState<boolean>(false)
  const [, { handleGoToGameDetail }] = usePlayGame()
  const { isFetchedAfterMount: isFetchedGameListAfterMount } = useListGame({})
  const isFavorite = useMemo(
    () => favIds.indexOf(game?.gameId) >= 0,
    [favIds, game],
  )
  const { mutate: updateFavorite } = useFavoriteGame()

  useEffect(() => {
    setDomMounted(true)
  }, [])

  const handleClick = async (e) => {
    e.stopPropagation()
    updateFavorite({ game: game, isFavorite: !isFavorite })
  }

  return (
    <>
      <div aria-hidden role="button" onClick={() => handleGoToGameDetail(game)}>
        {game?.isUnderConstruction && (
          <div
            className={cx(styles['under-construction'], {
              [styles.baccarat]: type === gameType.BACCARAT,
              [styles.gameshow]: type === gameType.GAMESHOW,
            })}
          >
            <span>ปิดปรับปรุง</span>
          </div>
        )}

        {isDomMounted && isAuthenticated && (
          <div
            role="button"
            className={cx([styles.favorite, { [styles.active]: isFavorite }])}
            onClick={handleClick}
            onKeyDown={handleClick}
          >
            {isFavorite ? <StarFilled /> : <StarOutlined />}
          </div>
        )}
        <div className={styles.live}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 9 }}>
            {game?.isLive && <LobbyGameLiveIcon />}
            <div className={styles.sound}>
              <SoundFilled className={styles['sound-icon']} />
              {game?.language === 'th' ? (
                <div className={styles.language}>ไทย</div>
              ) : (
                <GlobalOutlined className={styles['global-icon']} />
              )}
            </div>
          </div>
        </div>
        {game?.players > 0 && (
          <div className={styles.people}>
            <div className={styles.panel}>
              <UserOutlined className={styles['people-icon']} />
              <div className={styles.text}>{game?.players}</div>
            </div>
          </div>
        )}

        <div
          className={cx(styles.card, {
            [styles.baccarat]: type === gameType.BACCARAT,
            [styles.gameshow]: type === gameType.GAMESHOW,
            [styles['image-under-construction']]: game?.isUnderConstruction,
          })}
        >
          <Image fill src={game?.thumbnailUrl} alt={game?.gameName} />
        </div>
      </div>

      <div className={styles.footer}>
        <p className={styles['game-name']}>
          {game?.gameNameTh || game?.gameName}
        </p>
        <Button
          type="primary"
          className={styles.button}
          {...(isFetchedGameListAfterMount && {
            onClick: () => onClickPlay(game),
          })}
          disabled={game?.isUnderConstruction}
        >
          เล่นเกม
        </Button>
      </div>
    </>
  )
}
