import { useMemo } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import { Section } from '@pig-frontend/uikit'
import { Image } from '@pig-frontend/uikit'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useMediaQuery } from '@pig-frontend/uikit'
import useCampaign from '@pig-casino/hooks/useCampaign'
import usePromotions from '@pig-casino/hooks/usePromotions'
import { useRouter } from 'next/router'
import { promotionImageAssetUrl } from '@pig-casino/utils/promotion-image-url'
import orderBy from 'lodash/orderBy'
import { pageLinkCommon } from '@pig-common/constants/page-name'
import { IPromotionsResultData } from '@pig-casino/types/Promotions.type'
import { ICampaignData } from '@pig-casino/types/Campaign.type'
import { userAgentType } from '@pig-common/types/Device.type'
import styles from './index.module.scss'

interface IPromotionSection {
  userAgent?: userAgentType
}

/**
 * Every page that imported this PromotionSection component, Must separate page to mobile and desktop
 * whenever promotion need SEO or using server-side props or static build.
 * These don't know window size before client mounted. Make promotion layout shift.
 *
 * Unless we don't want SEO from this component anymore, you can delete it.
 */
export default function PromotionSection({ userAgent }: IPromotionSection) {
  const router = useRouter()
  const isMD = useMediaQuery('md', userAgent)
  const { data: campaigns = [] } = useCampaign({
    params: { productType: 'PIG_BET' },
  })
  const { data: promotions = [] } = usePromotions({
    params: { productType: 'PIG_BET' },
  })
  const allPromotions = useMemo(() => {
    return orderBy(
      [...campaigns, ...promotions],
      'order',
    ) as (IPromotionsResultData & ICampaignData)[]
  }, [promotions, campaigns])

  const handleClick = (isCampaign, code) => {
    if (isCampaign) {
      router.push(`${pageLinkCommon.campaigns}/${code}`)
    } else {
      router.push(`${pageLinkCommon.promotions}/${code}`)
    }
  }

  return (
    <Section
      title="โปรโมชั่น เครดิตฟรี เว็บคาสิโนออนไลน์"
      className={styles.container}
      sectionClassName={styles['promotion-section']}
      extra={
        <div className={styles['section-extra']}>
          <ArrowRightOutlined
            style={{ fontSize: 16 }}
            onClick={() => router.push(pageLinkCommon.promotions)}
          />
        </div>
      }
    >
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        modules={[Pagination]}
        className={styles['swiper-container']}
        pagination={{
          clickable: true,
        }}
      >
        {allPromotions.map((promotion) => (
          <SwiperSlide
            key={promotion.code}
            className={styles.slide}
            style={{ aspectRatio: isMD ? '1044 / 314.46' : '380 / 248.19' }}
          >
            <Image
              src={promotionImageAssetUrl(
                promotion.type === 'campaign' ? 'campaigns' : 'promotions',
                promotion.code,
                `banner-${isMD ? 'md-lg-xl.webp' : 'xs-sm.webp'}`,
              )}
              onClick={() =>
                handleClick(promotion.type === 'campaign', promotion.code)
              }
              alt={promotion.name}
              className={styles.img}
              width={isMD ? 1050 : 492}
              height={isMD ? 235 : 321}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Section>
  )
}
