import { Section } from '@pig-frontend/uikit'
import { Image } from '@pig-frontend/uikit'
import { rewardsConfig } from './config'
import styles from './index.module.scss'

export default function AwardSection() {
  return (
    <Section title="รางวัล" className={styles.container}>
      {rewardsConfig.map(({ key, image, text }) => (
        <div key={key} className={styles['item-container']}>
          <div className={styles.item}>
            <Image src={image} alt={key} className={styles.img} />
          </div>
          <span className={styles.text}>{text}</span>
        </div>
      ))}
    </Section>
  )
}
